import * as React from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { detect } from 'detect-browser';
import moment from 'moment-timezone';
import { GlobalStore, Dispatch } from './reduxStore';
import { translateService } from './services/TranslateService';
import * as analyticsService from './services/AnalyticsService';
import { PaymentRedirectResponse } from './interfaces/PaymentForm';
import {
    AsyncMakeReservation,
    AsyncReceipt,
    AsyncDeposit,
    AsyncPaymentScreen,
    AsyncUnpaidBalance,
} from './AsyncComponents';
import './locales/index';
import './App.scss';
import ScrollToTop from "./ScrollToTop";
// import { PaymentRedirectResponse } from './interfaces/PaymentForm';
import ServiceWorkerWrapper from "./ServiceWorkerWrapper";
import { Reservation } from './interfaces/Reservation';

// define which routes can be accessed by only authenticated, only unathenticated, or either
const authenticatedPages: string[] = ['/makereservation', '/myreservations', '/profile', '/reservationDetails', '/deposit', '/receipt', '/payment', '/extras', '/res', '/profile/1', '/profile/2', '/profile/3', '/balance'];
const unauthenticatedPages: string[] = ['/login', '/'];
// /myreservations included below because a link with hash can go directly there
const eitherPages: string[] = ['/r', '/#/', '/myreservations'];

const AsyncRootContainer = React.lazy(() => import("./components/RootContainer/RootContainer"));
const AsyncLoginContainer = React.lazy(() => import("./components/Login/LoginContainer"));

interface AppState {
    postLogin: string;
    hasSetRedirect: boolean;
    firstRender: boolean;
}

interface StateFromProps {
    // Redux store values fed in as props
    dbToken: string;
    language: string;
    API_VERSION: string;
    reservation: Reservation | undefined;
}

interface DispatchFromProps {
    // Redux dispatch options referenced as props
    onBrowserSet: (value: any) => void;
    onVersionSet: (version: string) => void;
    onApiVersionSet: (ver: string) => void;
    onApiUrlSet: (url: string) => void;
    onTokenGet: () => void;
    onSetLanguage: (language: string) => void;
    onSetDeferredPrompt: (deferredPromot: any) => void;
    onSetPcSkip: () => void;
    setPaymentResponse: (payRedirectResponse: PaymentRedirectResponse) => void;
}

class App extends React.Component<StateFromProps & DispatchFromProps, AppState> {
    constructor(props: any) {
        super(props);
        this.state = {
            postLogin: '',
            hasSetRedirect: false,
            firstRender: true,
        }
        analyticsService.initialise();
        // analyticsService.pageView('/');
        translateService.setLocale(this.props.language);
        moment.tz.setDefault('Europe/Helsinki');
        this.props.onTokenGet();
        document.documentElement.style.setProperty('--reactwindowheight', `${window.innerHeight}px`);

    }

    runRouteChangeRedirect() {
        this.props.onTokenGet();
        const isAuthenticated =
            (this.props.dbToken !== '' && this.props.dbToken !== undefined) || this.props.API_VERSION === 'mock';
        const redirectLink = checkPageAllowed(window.location.pathname, isAuthenticated, this.state.postLogin, this.state.firstRender);
        if (redirectLink && redirectLink === 'login') {
            this.setState({ postLogin: window.location.pathname + window.location.search + window.location.hash });
        }
        if (this.state.hasSetRedirect === false) {
            this.setState({ hasSetRedirect: true });
        }

    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const urlApiParam = urlParams.get('api') ?? undefined;
        const urlPcParam = urlParams.get('pc') ?? undefined;
        let mockReloaded = sessionStorage.getItem('mock') ?? undefined; // for window reload state
        let mockReloadPc = sessionStorage.getItem('pcCheck') ?? undefined; // for window reload state
        const hideRes = urlParams.get("hideres") || "";
        const hideMenu = urlParams.get("hidemenu") || "";

        if (hideRes === "1" && hideMenu === "1") {
            sessionStorage.setItem('L-M', "1"); // launcher mode
        }

        if (urlApiParam === 'mock') {
            // if api url parameter is lost from url redirects,...
            // session storage saves api version (mock state) if user reloads window
            sessionStorage.setItem('mock', urlApiParam);
            if (urlPcParam === 'true') {
                sessionStorage.setItem('pcCheck', 'true');
            }
        }

        if (mockReloaded && mockReloaded !== 'mock') {
            mockReloaded = undefined;
        }
        if (mockReloadPc && mockReloadPc !== 'true') {
            mockReloadPc = undefined;
        }

        this.setApiVersion(urlApiParam || mockReloaded || undefined);

        if ((urlPcParam !== 'true' || mockReloadPc) && (urlApiParam === 'mock' || mockReloaded)) { this.props.onSetPcSkip() }
        if (urlParams.get('lang') === 'en') { translateService.setLocale('en') }

        const { version } = require('../package.json');
        this.props.onVersionSet(version);
        const browser = detect();
        this.setState({ firstRender: false });

        this.props.onBrowserSet({
            ...browser,
            PWA: (typeof window.matchMedia === "function") && window.matchMedia('(display-mode: standalone)').matches,
        });

        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            this.props.onSetDeferredPrompt(e);
        });

        window.addEventListener('resize', () => {
            // We execute the same script as before
            document.documentElement.style.setProperty('--reactwindowheight', `${window.innerHeight}px`);
        });

        // log the page routes to Google Analytics each time
        // React.useEffect(() => {
        //     analyticsService.pageView(location.pathname);
        //   }, [location]);

        this.runRouteChangeRedirect();
    }

    componentDidUpdate() {
        this.runRouteChangeRedirect();
    }

    render() {
        const isAuthenticated = (this.props.dbToken !== '' && this.props.dbToken !== undefined) || this.props.API_VERSION === 'mock';
        const redirectLink = checkPageAllowed(window.location.pathname, isAuthenticated, this.state.postLogin, this.state.firstRender);

        if (window.location.hash !== '') {
            const redirectValue = this.chauffeurLinkToChauffeurRes(window.location.hash);
            return <Navigate replace to={redirectValue} />;
        }

        if (redirectLink) {
            if (redirectLink === 'login') {
                return (<Navigate replace to='/login' />);
            }
            if (this.state.postLogin) {
                setTimeout(() => {
                    this.forceUpdate(); // refreshes page to avoid grey screen for user after returning to post login url
                    this.setState({ postLogin: '' }) // removes postLogin string after used
                }, 100);
            }
            return redirectLink;
        }


        let resId = window.location.pathname.slice(1);
        resId = resId.slice(resId.indexOf('/') + 1)

        /* this empty onClick is necessary for touch-action:manipulate to work on ios https://bugs.webkit.org/show_bug.cgi?id=149854#c25 */
        return (
            <div onClick={e => e}>
                <ServiceWorkerWrapper />
                <ScrollToTop />
                <React.Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                        <Route path='/login' element={
                            <AsyncLoginContainer />
                        } />
                        <Route path='/' element={
                            <AsyncLoginContainer />
                        } />
                        <Route path='/myreservations' element={
                            <AsyncRootContainer
                                content="myreservations"
                                title={translateService.translate("rootContainer.reservations")} />}
                        />
                        <Route path='/reservationDetails/:id' element={
                            <AsyncRootContainer content="reservationDetails"
                                title={`${translateService.translate('Reservation')} #${this.props.reservation?.id || ''}`}
                                reservation_id={this.props.reservation?.id || -1}
                            />}
                        />
                        <Route path='/extras/:id' element={
                            <AsyncRootContainer content="reservationDetails"
                                title={`${translateService.translate('Reservation')} #${this.props.reservation?.id || ''}`}
                                reservation_id={this.props.reservation?.id || -1}
                            />}
                        />
                        <Route path='/res/:id' element={
                            <AsyncRootContainer content="reservationDetails"
                                title={`${translateService.translate('Reservation')} #${this.props.reservation?.id || ''}`}
                                reservation_id={this.props.reservation?.id || -1}
                            />}
                        />
                        <Route path='/r/:reservation_id' element={
                            <AsyncRootContainer content="phase" title={translateService.translate("rootContainer.phase")} />}
                        />
                        <Route path='/makereservation' element={
                            <AsyncMakeReservation content="makereservation"
                                title={translateService.translate("rootContainer.reservations")} />}
                        />
                        <Route path='/profile' element={
                            <AsyncRootContainer
                                content="profile"
                                title={translateService.translate("rootContainer.profile")}
                            />}
                        />

                        <Route path='/deposit' element={
                            <AsyncDeposit content="deposit" title={translateService.translate("rootContainer.deposit")} />}
                        />

                        <Route path='/balance' element={
                            <AsyncUnpaidBalance content="unpaidBalance" title={translateService.translate("rootContainer.unpaidBalance")} />}
                        />

                        <Route path='/deposit/:id' element={
                            <AsyncDeposit content="deposit" title={translateService.translate("rootContainer.deposit")} />}
                        />


                        <Route path='/receipt/:id' element={<AsyncReceipt content="receipt" title={"Receipt"} />} />
                        {/*<Route path='/damages' element={<AsyncDamages />} />*/}

                        <Route path='/payment/:paymentId/:paymentStatus?' element={
                            <AsyncPaymentScreen content="paymentscreen" title={translateService.translate("rootContainer.payment")} />}
                        />

                        <Route path='/payment/:paymentId' element={
                            <AsyncPaymentScreen content="paymentscreen" title={translateService.translate("rootContainer.payment")} />}
                        />

                        {/*Next Route Handles Unrecognised Url Input */}
                        <Route path='*' element={isAuthenticated ? <Navigate replace to={'/myreservations'} /> : <Navigate replace to={'/login'} />} />

                    </Routes>
                </React.Suspense>
            </div>
        );
    }

    setApiVersion = (queryStringApiValue: string | string[] | undefined) => {
        // if an api= value was passed, use it
        this.props.onApiVersionSet(typeof queryStringApiValue === 'string' ? queryStringApiValue : 'v3');

        // set the URL according to the .env values present
        this.props.onApiUrlSet(
            typeof queryStringApiValue === 'string' && queryStringApiValue !== 'mock' && process.env
                ? process.env['REACT_APP_API_' + queryStringApiValue.toUpperCase() + '_URL'] || ''
                : process.env.REACT_APP_API_V3_URL || ''
        );
    };

    // convert:
    // #/reservationId?h=authHash to
    // /r/reservationId?h=authHash
    // also added:
    // #/?c=customerId&h=authHash to
    // /myreservations?h=authHash
    chauffeurLinkToChauffeurRes = (chauffeurLink: string) => {
        const urlParams = new URLSearchParams(chauffeurLink.slice(2));

        if (chauffeurLink[2] === '?') {
            return `/myreservations?h=${urlParams.get('h')}&c=${urlParams.get('c')}`;
        } else {
            const reservationId = chauffeurLink.substring(2, chauffeurLink.indexOf('?'));
            const authenticationHash = urlParams.get('h');

            if (isNaN(parseInt(reservationId, 10)) || isNaN(parseInt('0x' + authenticationHash, 16))) {
                return '/';
            }

            return `/r/${reservationId}?h=${authenticationHash}`;
        }
    };
}

const checkPageAllowed = (pageName: string, isAuthenticated: boolean, postLogin: string, isFirstRender: boolean): React.ReactElement | undefined | string => {
    // if we're in the wrong auth state, prepare to redirect to a page of the appropriate state
    if (isInPageArray(pageName, eitherPages)) {
        return undefined;
    }
    else if (!isAuthenticated && isInPageArray(pageName, authenticatedPages)) {
        // alert("here is the inject for a redirect post-login ");
        if (isFirstRender) return undefined; // allows to skip this case for initial render to get correct authentication state (if already logged in)
        return 'login';
    } else if (isAuthenticated && isInPageArray(pageName, unauthenticatedPages)) {
        if (postLogin !== '') {
            return <Navigate replace to={postLogin} />;
        }
        // console.log('Authenticated, so redirecting to /myreservations');
        // if authenticated and manual url pasted, redirect to my reservations automatically
    } else if (
        !isInPageArray(pageName, authenticatedPages) &&
        !isInPageArray(pageName, unauthenticatedPages) &&
        isAuthenticated
    ) {
        // console.log(`Page ${pageName} doesn't exist, so sending to authenticated page ${authenticatedPages[0]}`);
        return <Navigate replace to={authenticatedPages[0]} />;
    } else if (
        !isInPageArray(pageName, authenticatedPages) &&
        !isInPageArray(pageName, unauthenticatedPages) &&
        !isAuthenticated
    ) {
        // console.log(`Page ${pageName} doesn't exist, so sending to unauthenticated page ${unauthenticatedPages[0]}`);
        return <Navigate replace to={unauthenticatedPages[0]} />;
    }
    return undefined;
};

function isInPageArray(page: string, pageArray: string[]): boolean {
    // only look at 'page' to the char before the 2nd '/'
    let pageBeforeSlash = page;
    if (page.indexOf('/', 1) !== -1) {
        pageBeforeSlash = page.substring(0, page.indexOf('/', 1));
    }
    const x = pageArray.filter((pageName) => pageName === pageBeforeSlash);
    return x.length > 0;
}

const mapStateToProps = (store: GlobalStore): StateFromProps => {
    // authToken becomes the prop on this container, for redux state's dbToken property
    return {
        dbToken: store.chauffeurReducer.dbToken || '',
        language: store.chauffeurReducer.deviceLanguage,
        API_VERSION: store.chauffeurReducer.API_VERSION,
        reservation: store.chauffeurReducer.reservation
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchFromProps => {
    return {
        onBrowserSet: (value: any) =>
            dispatch({
                type: 'SET_BROWSER',
                payload: value,
            }),
        onVersionSet: (version: string) =>
            dispatch({
                type: 'SET_VERSION',
                payload: version,
            }),
        onApiUrlSet: (url: string) =>
            dispatch({
                type: 'SET_API_URL',
                payload: url,
            }),
        onApiVersionSet: (ver: string) =>
            dispatch({
                type: 'SET_API_VERSION',
                payload: ver,
            }),
        onTokenGet: () => {
            dispatch({
                type: 'GET_TOKEN',
            });
        },
        onSetLanguage: (language: string) =>
            dispatch({
                type: 'SET_LANG',
                payload: language,
            }),
        onSetDeferredPrompt: (deferredPrompt: any) => {
            dispatch({
                type: 'SET_DEFERRED_PROMPT',
                payload: deferredPrompt,
            });
        },
        onSetPcSkip: () =>
            dispatch({
                type: 'SET_PC_SKIP',
                payload: true,
            }),
        setPaymentResponse: (payRedirectResponse: PaymentRedirectResponse) =>
            dispatch({
                type: 'SET_PAY_REDIRECT',
                payload: payRedirectResponse
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
